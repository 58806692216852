<template>
  <div>
    <v-subheader>{{ $t('tasks') }}</v-subheader>
    <v-list
      v-if="tasks !== null && tasks.length > 0"
      class="pt-0 pb-0"
    >
      <template v-for="(task, index) in tasks">
        <OrderTaskListItem
          :key="task.uuid"
          :task="task"
        />
        <v-divider
          v-if="tasks.length -1 > index"
          :key="'div' + index"
        />
      </template>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>{{ $t('noTasks') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import OrderTaskListItem from "@/components/order/OrderTaskListItem"

export default {
  name: "OrderTaskList",
  props: {
    tasks: {
      type: Array,
      default: () => []
    }
  },
  components: {
    OrderTaskListItem
  }
}
</script>

<style scoped>

</style>
