<template>
  <v-list-item v-if="task">
    <v-tooltip
      right
      color="black"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar
          v-bind="attrs"
          v-on="on"
          size="28"
          :color="statusColor"
        >
          <v-icon dark>
            {{ statusIcon }}
          </v-icon>
        </v-list-item-avatar>
      </template>
      <span class="text-capitalize">
        {{ task.status }}
      </span>
    </v-tooltip>
    <v-list-item-content class="align-self-start">
      <v-list-item-title> {{ task.namespace }} </v-list-item-title>
      <v-list-item-subtitle>
        {{ itemLabel }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="align-self-start">
      <v-list-item-title v-if="task.user">
        {{ $t('User') }}: {{ userName }}
      </v-list-item-title>
      <v-list-item-title v-if="!task.user || task.staff">
        {{ $t('staff') }}: {{ staffName }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ taskMessage }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-subtitle>
        {{ $t('created') }}: {{ createdTimestamp | date }},
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ $t('changed') }}: {{ changedTimestamp | date }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>


export default {
  name: "OrderTaskListItem",
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
	computed: {
		taskMessage() {
			if(typeof this.task === 'undefined' || this.task === null) {
				return this.$t('unavailable')
			}
			if(typeof this.task.message === 'undefined' || this.task.message === null) {
				return this.task.status
			}
			return this.task.message
		},
		statusColor() {
			if(typeof this.task === 'undefined' || this.task === null) {
				return 'grey'
			}
			switch (this.task.status) {
				case 'completed':
					return 'green'
				case 'pending':
					return 'blue'
				case 'error':
					return 'red'
				case 'cancelled':
					return 'grey'
				case 'canceled':
					return 'grey'
				default:
					return 'grey'
			}
		},
		statusIcon() {
			if(typeof this.task === 'undefined' || this.task === null) {
				return 'mdi-help'
			}
			switch (this.task.status) {
				case 'completed':
					return 'mdi-check-bold'
				case 'pending':
					return 'mdi-dots-horizontal'
				case 'error':
					return 'mdi-exclamation-thick'
				case 'cancelled':
					return 'mdi-cancel'
				case 'canceled':
					return 'mdi-cancel'
				default:
					return 'mdi-help'
			}
		},
    staffName() {
     if (typeof this.task !== 'undefined' && this.task !== null
       && typeof this.task.staff !== 'undefined' && this.task.staff !== null
       && typeof this.task.staff.address !== 'undefined' && this.task.staff.address
       && typeof this.task.staff.address.name_line === 'string' && this.task.staff.address.name_line.trim() !== '')
     {
       return this.task.staff.address.name_line
      }
      return this.$t('unavailable')
    },
	userName() {
		if(typeof this.task !== 'undefined' && this.task !== null) {
			if(typeof this.task.user !== 'undefined' && this.task.user !== null) {
				if(typeof this.task.user.displayName !== 'undefined' && this.task.user.displayName !== null) {
					return this.task.user.displayName
				}
			}
		}
		return this.$t('unavailable')
	},
	createdTimestamp() {
		if(typeof this.task === 'undefined' || this.task === null || isNaN(Number(this.task.created))) {
			return 0
		}
		return this.$store.getters.validateAndGetEpochTimestampInMillis(this.task.created)
	},
	changedTimestamp() {
		if(typeof this.task === 'undefined' || this.task === null || isNaN(Number(this.task.changed))) {
			return 0
		}
		return this.$store.getters.validateAndGetEpochTimestampInMillis(this.task.changed)
	},
	  itemLabel() {
      if( typeof this.task.line_item !== 'undefined' && this.task.line_item !== null && typeof this.task.line_item.line_item_label !== 'undefined' && this.task.line_item.line_item_label !== null ) {
        return this.task.line_item.line_item_label
      }
      return this.$t('unavailable')
    }
  }
}
</script>

<style scoped>

</style>
